<template>
  <b-container class="about">
    <h1 class="text--white">Plaid Account Integration</h1>

    <b-row>
      <b-col
        cols="12"
        class="mt-4"
      >
        <b-card
          class="h-100"
        >
          <b-card-title>Current Accounts</b-card-title>
          <b-overlay
            :show="loading"
          >
            <b-table-simple class="table-striped table-condensed table-sm table-hover">
              <b-thead>
                <b-tr>
                  <b-th>Bank</b-th>
                  <b-th class="text-left">Account</b-th>
                  <b-th>Status</b-th>
                </b-tr>
              </b-thead>
              <b-tbody v-if="items && items.length > 0">
                <b-tr
                  v-for="i in items"
                  :key="(i.id) ? i.id : 'pi' + i.plaiditem_id"
                >
                  <b-td>{{ i.institution }}</b-td>
                  <b-td class="text-left">{{ i.name }}</b-td>
                  <b-td>
                    <span v-if="i.needsauth">
                      <b-icon-bug-fill class="text-danger"></b-icon-bug-fill> Error
                    </span>
                    <span v-else-if="!i.type">
                      <b-icon-cloud-download-fill class="text-warning"></b-icon-cloud-download-fill> Synchronizing
                    </span>
                    <span v-else>
                      <b-icon-check-circle-fill class="text-success"></b-icon-check-circle-fill> Connected
                    </span>
                  </b-td>
                </b-tr>
              </b-tbody>
              <b-tbody v-else>
                <b-tr>
                  <b-td colspan="3">No accounts yet.</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-overlay>
          <b-button
            @click.prevent="load"
            size="sm"
            variant="primary"
            :disabled="loading"
          >Refresh</b-button>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        class="mt-4"
      >
        <b-card
          class="h-100"
        >
          <b-card-title>Add a New Plaid Account</b-card-title>
          <b-row class="text-left">
            <b-col>
              <p>Plaid.com allows us to safely and securely retrieve transactions directly from your checking and credit card accounts. Plaid interacts directly with your banks authentication system, allowing us to retrieve data without storing your credentials or account numbers.</p>
              <p>Please follow the steps below to add a new account:</p>
              <ol>
                <li>Click the 'Add a New Bank Account' button below.</li>
                <li>Select your bank from the list or search by name. If your institution is not listed, it may not be supported yet. Please <a href="mailto:devteam@aimitservices.com">contact us</a>.</li>
                <li>Follow the authentication and authorization prompts provided by your financial institution.</li>
                <li>If prompted to select accounts, we likely only need your checking/savings and credit card accounts.</li>
                <li>Upon success, the newly authorized account should appear in your list. Click the refresh button to check the status. Accounts will synchronize within a few minutes.</li>
                <li>If you receive any errors, please <a href="mailto:devteam@aimitservices.com">let us know</a>.</li>
              </ol>
            </b-col>
          </b-row>
          <PlaidLink
            :company-id="companyId"
            button-title="Add a New Bank Account"
            @refresh="load"
          ></PlaidLink>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import StorageService from '@/services/StorageService'
import UserService from '@/services/UserService'
import PlaidService from '@/services/PlaidService'
import PlaidLink from '@/components/plaid/Link'
import AdminService from '@/services/AdminService'

export default {
  name: 'Plaid',
  components: { PlaidLink },
  data () {
    return {
      loading: false,
      user: null,
      companyId: null,
      items: []
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      this.user = StorageService.get('user')
      let companies = null
      if (UserService.isAdmin()) {
        companies = await AdminService.getCompanies()
      } else {
        companies = await UserService.getCompanies()
      }
      if (companies && companies.length === 1) {
        this.companyId = +companies[0].id
        this.load()
      }
    },

    load () {
      this.loading = true
      PlaidService.getCompany(this.companyId).then((response) => {
        this.items = response.data.info.plaid
      }).catch((err) => {
        this.$aimNotify.error(err.response)
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>
